<template>
  <div id="tab-employee">
    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida por funcionário..." />
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="payload.limit"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'

// Cell Renderer
import CellRendererDateTime from './cell-renderer/CellRendererDateTime.vue'
import CellRendererActions  from './cell-renderer/CellRendererActions.vue'

import moduleReceipt from '@/store/receipt/moduleReceipt.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererDateTime,
    CellRendererActions
  },
  data () {
    return {
      popupShowPdf: false,
      showPdfTitle: '',
      srcPdf: '',

      searchQuery: '',

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true
      },

      // AgGrid
      localeText: null,
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Número',
          field: 'number',
          width: 125
        },
        {
          headerName: 'Situação',
          field: 'canceled',
          valueFormatter: params => { return params.value ? 'CANCELADO' : 'ATIVO' },
          width: 150
        },
        {
          headerName: 'Valor',
          field: 'value',
          valueFormatter: params => { return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' },
          width: 150
        },
        {
          headerName: 'Cliente',
          field: 'transaction.student.name',
          width: 250
        },
        {
          headerName: 'Emissor',
          field: 'created_by.name',
          width: 250
        },
        {
          headerName: 'Emitido em',
          field: 'created_at',
          width: 150,
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Cancelado por',
          field: 'canceled_by.name',
          width: 250
        },
        {
          headerName: 'Cancelado em',
          field: 'canceled_at',
          width: 150,
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Ações',
          field: 'actions',
          width: 95,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            print: this.print // usado para passar parametros para dentro o componente renderizado no ag grid
          }
        }
      ],
      components: {
        CellRendererDateTime,
        CellRendererActions
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    receipt () {
      return this.$store.state.receipt.receipt
    },
    allData () {
      return this.$store.state.receipt.receiptsGrid
    }
  },
  methods: {
    async print (data) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_recibo')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      try {
        this.$vs.loading()
        this.showPdfTitle = 'Recibo'
        const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/document/pdf/receipt/${data.data.transaction.id}`)
        this.srcPdf = `data:application/pdf;base64,${resp.data.pdf}`
        this.popupShowPdf = true
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } catch (error) {
        console.log(error)
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    refreshGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.setDatasource(this.dataSource)
      // this.gridApi.refreshInfiniteCache() // REMOVI ESSE PQ QDO NÃO HÁ NENHUM REGISTRO NÃO ATUALIZA AO CADASTRAR O PRIMEIRO
    },
    refreshInfiniteCacheGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },
    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('receipt/fetchGrid', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return data
    }
  },
  mounted () {
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('allData')
    if (!moduleReceipt.isRegistered) {
      this.$store.registerModule('receipt', moduleReceipt)
      moduleReceipt.isRegistered = true
    }
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
  }
}
</script>

<style>
  .popup80 .vs-popup {
    width: 80% !important;
  }
  .noOverflow .vs-popup--content {
    overflow: hidden;
  }
</style>
