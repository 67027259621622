import Vue from 'vue'

export default {
  SET (state, payload) {
    state.singleReceipt = Object.assign({}, state.singleReceipt, payload) //precisa ser assim para manter a reatividade
  },
  SET_RECEIPTS_GRID (state, payload) {
    state.singleReceiptsGrid = payload
  },
  STORE (state, payload) {
    state.singleReceipts.push(payload) //precisa ser assim para manter a reatividade
    state.singleReceipts.sort((a, b) => { return new Date(a.data) - new Date(b.data) }) //ORDENA POR DATA

    state.singleReceiptsGrid.data.push(payload)
    state.singleReceiptsGrid.data.sort((a, b) => { return new Date(a.data) - new Date(b.data) }) //ORDENA POR DATA
    state.singleReceiptsGrid.total = state.singleReceiptsGrid.total + 1

  },
  UPDATE (state, payload) {
    const idx = state.singleReceipts.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.singleReceipts, idx, payload)

    const idx1 = state.singleReceiptsGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.singleReceiptsGrid.data, idx1, payload)
  },
  DELETE (state, payload) {
    const idx1 = state.singleReceipts.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.singleReceipts.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.singleReceiptsGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.singleReceiptsGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.singleReceiptsGrid.total = state.singleReceiptsGrid.total - 1
    }
  }
}
