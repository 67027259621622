import { render, staticRenderFns } from "./SingleReceipts.vue?vue&type=template&id=9b909f7c&"
import script from "./SingleReceipts.vue?vue&type=script&lang=js&"
export * from "./SingleReceipts.vue?vue&type=script&lang=js&"
import style0 from "./SingleReceipts.vue?vue&type=style&index=0&id=9b909f7c&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9b909f7c')) {
      api.createRecord('9b909f7c', component.options)
    } else {
      api.reload('9b909f7c', component.options)
    }
    module.hot.accept("./SingleReceipts.vue?vue&type=template&id=9b909f7c&", function () {
      api.rerender('9b909f7c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/receipts/tabs/single-receipts/SingleReceipts.vue"
export default component.exports