<template>
  <div>
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Recibos Avulsos" icon-pack="feather" icon="icon-bookmark">
            <div class="tab-text">
              <single-receipts class="mt-4" ref="single-receipts" />
            </div>
          </vs-tab>
          <vs-tab label="Recibos" icon-pack="feather" icon="icon-bookmark">
            <div class="tab-text">
              <receipts class="mt-4" ref="receipts" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>

import Receipts from './tabs/receipts/Receipts.vue'
import SingleReceipts from './tabs/single-receipts/SingleReceipts.vue'

export default {
  components: {
    Receipts,
    SingleReceipts
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>
