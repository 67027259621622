import axios from '@/axios.js'

export default {
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/singleReceipt`, payload)
        .then((response) => {
          commit('STORE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  cancel ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/singleReceipt/cancel/${payload}`, payload)
        .then((response) => {
          commit('SET', response.data)
          commit('UPDATE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  reactivate ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/singleReceipt/reactivate/${payload}`, payload)
        .then((response) => {
          commit('SET', response.data)
          commit('UPDATE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/singleReceipts`, payload)
        .then((response) => {
          commit('SET_RECEIPTS_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
