<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon v-if="params.data && !params.data.canceled" icon="PrinterIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="print" />
      <feather-icon v-if="params.data && !params.data.canceled" title="Cancelar este recibo" icon="SlashIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="singleReceiptCancel" />
      <feather-icon v-if="params.data && params.data.canceled" title="Reativar este recibo" icon="RefreshCcwIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="singleReceiptReactivate" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    print () {
      this.params.print(this.params) // assim chamo a função de fora
    },
    singleReceiptCancel () {
      this.params.singleReceiptCancel(this.params) // assim chamo a função de fora
    },
    singleReceiptReactivate () {
      this.params.singleReceiptReactivate(this.params) // assim chamo a função de fora
    }
  }
}
</script>
