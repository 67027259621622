import state from './moduleReceiptState.js'
import mutations from './moduleReceiptMutations.js'
import actions from './moduleReceiptActions.js'
import getters from './moduleReceiptGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

