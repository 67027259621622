import state from './moduleSingleReceiptState.js'
import mutations from './moduleSingleReceiptMutations.js'
import actions from './moduleSingleReceiptActions.js'
import getters from './moduleSingleReceiptGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

